<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return"><el-button type="text" size="small"
						@click="config.isDetailDisplay = false">返回</el-button> </p>
				<div class="h-b"><el-button type="primary" size="small" @click="editApi"
						v-if="!config.isAdd && config.isCanEdit" v-right-code="'SiteInfo:Edit'">编辑</el-button>
					<el-button type="primary" size="small" @click="saveApi" v-if="config.isAdd"
						v-right-code="'SiteInfo:Edit'">保存</el-button>
				</div>
				<div class="h-b">
				</div>
			</div>
		</div>

		<el-tabs type="border-card" v-model="activeName">
			<el-tab-pane name="tabbasic">
				<span slot="label">基本信息</span>
				<el-form ref="_editForm" :model="dataSource" :rules="checkRule">
					<el-collapse v-model.trim="formItems">
						<el-collapse-item title="基本信息" name="formItem">
							<el-form-item>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>中转点编码：</el-col>
								<el-col :span="5">
									<el-form-item prop="Code">
										<el-input v-model.trim="dataSource.Code" :maxlength="30" :minlength="4"
											:disabled="true" :readonly="true" placeholder="系统自动生成"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>中转点名称：</el-col>
								<el-col :span="5">
									<el-form-item prop="Name">
										<el-input v-model.trim="dataSource.Name" :disabled="!config.isAdd"
											:maxlength="50" placeholder="中转点名称：" autofocus="autofocus"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>中转点类型：</el-col>
								<el-col :span="5">
									<el-form-item prop="SiteType">
										<DictionarySelect v-model="dataSource.SiteType"  dicTypeCode="SiteInfoTypeCode" datType='Int'
											:disabled="!config.isAdd">
										</DictionarySelect>
										<!-- <el-select v-model="dataSource.SiteType" placeholder="请选择"
											:disabled="!config.isAdd">
											<el-option v-for="item in siteList" :key="item.Key" :label="item.Value"
												:value="item.Key">
											</el-option>
										</el-select> -->
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>上级中转点：</el-col>
								<el-col :span="5">
									<el-form-item prop="ParentId">
										<LookPopup v-model="dataSource.ParentName" dataTitle="供应商选择"
											dataUrl='omsapi/siteinfo/getpagelist' syncField='Name'
											@syncFieldEvent="syncSiteEvent" :disabled="!config.isAdd">
										</LookPopup>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title"> <span style="color:red;">*</span>中转点级别：</el-col>
								<el-col :span="5">
									<el-form-item prop="Level">
										<DictionarySelect v-model="dataSource.Level" dicTypeCode="SiteInfoLevelCode"
											:disabled="!config.isAdd" datType='Int'>
										</DictionarySelect>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>状态：</el-col>
								<el-col :span="5">
									<el-form-item prop="Status">
										<el-select v-model="dataSource.Status" placeholder="请选择"
											:disabled="!config.isAdd">
											<el-option v-for="item in statusList" :key="item.Key" :label="item.Value"
												:value="item.Key">
											</el-option>
										</el-select>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="3" class="form-title"><span style="color:red;"></span>所属供应商：</el-col>
								<el-col :span="5">
									<el-form-item prop="SupplierId">
										<!-- <el-input v-model="dataSource.SupplierId" placeholder="所属供应商"
											type="number"></el-input> -->
										<LookPopup v-model="dataSource.SupplierName" dataTitle="供应商选择"
											dataUrl='omsapi/customervendor/getlist' syncField='SupplierName'
											@syncFieldEvent="syncSupplierEvent" :disabled="!config.isAdd">
										</LookPopup>

									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="3" class="form-title">业务区域：</el-col>
								<el-col :span="21">
									<el-form-item prop="RegionCodes">
										<el-cascader v-model="dataSource.RegionCodeList" :options="districtOptions"
											:props="{ multiple: true, checkStrictly: true, emitPath:false}" collapse-tags
											clearable></el-cascader>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>中转点地址：</el-col>
								<el-col :span="21">
									<el-form-item>
										<Distpicker :placeholders="dataSource" :disabled="!config.isAdd"></Distpicker>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>详细地址：</el-col>
								<el-col :span="21">
									<el-form-item prop="Address">
										<el-input v-model="dataSource.Address" placeholder="业务区域"
											:disabled="!config.isAdd"></el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>中转点联系人：</el-col>
								<el-col :span="5">
									<el-form-item prop="StaffId">
										<LookPopup v-model="dataSource.StaffName" dataTitle="中转点联系人"
											dataUrl='omsapi/sysstaff/getpagelist' syncField='Name'
											@syncFieldEvent="syncStaffEvent" :disabled="!config.isAdd">
										</LookPopup>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>中转点联系人电话：</el-col>
								<el-col :span="5">
									<el-form-item prop="ContactPhone">
										<el-input v-model="dataSource.ContactPhone" placeholder="中转点联系人电话"
											:disabled="!config.isAdd"></el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="3" class="form-title"> 工作开始时间：</el-col>
								<el-col :span="5">
									<el-form-item prop="CutStartTime">
										<el-time-picker v-model.trim="dataSource.CutStartTime" size="mini"
											placeholder="选择时间" format="HH:mm" value-format="HH:mm"></el-time-picker>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title"> 工作结束时间：</el-col>
								<el-col :span="5">
									<el-form-item prop="CutOrderTime">
										<el-time-picker v-model.trim="dataSource.CutOrderTime" size="mini"
											placeholder="选择时间" format="HH:mm" value-format="HH:mm"></el-time-picker>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="3" class="form-title">备注：</el-col>
								<el-col :span="21">
									<el-form-item prop="Remarks">
										<el-input type="textarea" :disabled="!config.isAdd"
											v-model.trim="dataSource.Remark" :maxlength="300"
											placeholder="备注"></el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
						</el-collapse-item>
						<el-collapse-item title="其他信息" name="formItem2">
							<el-form-item>
								<el-col :span="3" class="form-title"><span style="color:red;"></span>所属组织：</el-col>
								<el-col :span="5">
									<el-form-item prop="OrganizationName">
										<el-input v-model="dataSource.OrganizationName" placeholder="所属组织"
											@focus="btnOrganization" :disabled="!config.isAdd"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title"><span style="color:red;"></span>所属大区：</el-col>
								<el-col :span="5">
									<el-form-item prop="CompanyName">
										<el-input v-model="dataSource.CompanyName" placeholder="所属分公司"
											@focus="btnCompany" :disabled="!config.isAdd"></el-input>
									</el-form-item>
								</el-col>
								<!-- 		<el-col :span="3" class="form-title"><span style="color:red;">*</span>所属大区：</el-col>
								<el-col :span="5">
									<el-form-item prop="Volume">
										<el-input v-model="dataSource.Volume" placeholder="体积(m³)"
											type="number"></el-input>
									</el-form-item>
								</el-col> -->
							</el-form-item>
						</el-collapse-item>
					</el-collapse>
				</el-form>
			</el-tab-pane>
		</el-tabs>
		<DialogEx :options="organizationOptions" :title="organizationOptions.title" @onOk="onOrganizationOk">
			<OrganizationSelector ref="organizationRef" :config="organizationConfig"
				@onSelectedRow="onOrganizationSelectedRow"></OrganizationSelector>
		</DialogEx>
	</div>
</template>
<script>
export default {
	mounted() {
		this.getDistrictOptionsList();
	},
	data() {
		return {
			isDisabled: false,
			activeName: "tabbasic",
			formItems: ['formItem', 'formItem2'],
			districtOptions: [],
			checkRule: {
				Name: [{
					required: true,
					message: '请输入中转点名称',
					trigger: 'blur'
				}],

			},
			siteList: [{
				Key: 100,
				Value: "主控中转点"

			},
			{
				Key: 200,
				Value: "分控中转点"
			},
			{
				Key: 300,
				Value: "国际中转点"
			}
			],
			statusList: [{
				Key: 100,
				Value: "启用"

			},
			{
				Key: 200,
				Value: "停用"
			}

			],
			levelList: [{
				Key: 100,
				Value: "是"

			},
			{
				Key: 200,
				Value: "否"
			}

			],
			organizationOptions: {
				title: "",
				visible: false,
				size: 'small'
			},
			organizationConfig: {
				code: null,
				type: null
			},
			dataSource: {

			}
		};
	},
	props: {
		config: {
			isDetailDisplay: false,
			dataSourceKey: null,
		}
	},
	watch: {
		'config.dataSourceKey': {
			handler(curVal, oldVal) {
				if (curVal && curVal > 0) {
					this.getDetail(curVal); //初始化
				} else {
					if (curVal == null) {
						this.dataSource = {};
						this.add();
					}

				}
			},
			deep: true
		},
	},
	methods: {

		add() {
			var _this = this;
			this.$ajax.send("omsapi/siteinfo/getempty", "get", {}, (data) => {
				_this.dataSource = data.Result;
			});
		},
		editApi() {
			this.config.isAdd = true;
		},
		detailApi(apiId) {
			this.config.isAdd = false;
		},
		getDetail(apiId) {
			var _this = this;
			this.$ajax.send("omsapi/siteinfo/get", "get", {
				id: apiId
			}, (data) => {
				_this.dataSource = data.Result;
			});
		},
		saveApi() {
			this.$refs["_editForm"].validate((valid) => {
				if (valid) {
					var actionName = this.dataSource.Id && this.dataSource.Id > 0 ? 'edit' : 'create';
					var obj = this.Utils.cloneObj(this.dataSource);
					this.$ajax.send("omsapi/siteinfo/" + actionName, "post", obj, (data) => {
						this.config.dataSourceKey = data.Result;
						this.config.isCanEdit = false;
						this.config.isAdd = false;
						this.Event.$emit("reloadSiteTreesList");
						this.Utils.messageBox("保存成功.", "success");
					});
				} else {
					this.Utils.messageBox("存在必填信息未输入.", "error");
					return false;
				}
			});
		},
		syncSupplierEvent(row) {
			if (row) {
				this.dataSource.SupplierId = row.VendorId;
				this.dataSource.SupplierName = row.VendorName;
			}

		},
		syncSiteEvent(row) {
			if (row) {
				this.dataSource.ParentId = row.Id;
				this.dataSource.ParentName = row.Name;
			}

		},
		syncStaffEvent(row) {
			if (row) {
				this.dataSource.StaffId = row.Id;
				this.dataSource.StaffName = row.Name;
				this.dataSource.ContactPhone = row.Phone;
			}
		},
		btnOrganization() {
			this.organizationOptions.title = "选择组织";
			this.organizationOptions.visible = true;
			this.organizationConfig.type = 0;
			this.organizationConfig.code = null;

		},
		btnCompany() {
			this.organizationOptions.title = "选择公司";
			this.organizationOptions.visible = true;
			this.organizationConfig.type = null;
			this.organizationConfig.code = 0;

		},
		onOrganizationSelectedRow(row) {
			if (row) {
				if (this.organizationConfig.type != null) {
					this.dataSource.OrganizationId = row.Id;
					this.dataSource.OrganizationName = row.Name;
				}
				if (this.organizationConfig.code != null) {
					this.dataSource.CompanyId = row.Id;
					this.dataSource.CompanyName = row.Name;
				}
			}
		},
		onOrganizationOk() {

			this.organizationConfig.type = null;
			this.organizationConfig.code = null;
			this.organizationOptions.title = "";
			this.organizationOptions.visible = false;
		},
		getDistrictOptionsList() {
			var _this = this;
			this.$ajax.query("omsapi/district/getTreeList", "get", {}, (data) => {
				if (data.IsSuccess) {
					_this.districtOptions = _this.Utils.iterationDelateMenuChildren(data.Result);
				} else {
					_this.Utils.messageBox("获取数据失败.", "error");
				}
			});
		},
	},
	components: {
		"OrganizationSelector": resolve => {
			require(['../../../components/organizationselector.vue'], resolve)
		}, //异常记录

	}
}
</script>